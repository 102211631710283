.component {
  & > .header {
    margin-bottom: var(--size-30);
  }
}

.header {
  color: var(--color-blue-700);
}

.item {
  overflow: hidden;

  &:first-child {
    border-radius: var(--border-radius-12) var(--border-radius-12) 0 0;
  }

  &:last-child {
    border-radius: 0 0 var(--border-radius-12) var(--border-radius-12);
  }
}

.list {
  padding: 0;
  list-style: none;
}
