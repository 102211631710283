.component {
  max-width: 100%;
  overflow: visible;
  position: relative;

  & > .navigation {
    margin-top: var(--size-12);

    @media (min-width: 1400px) {
      position: absolute;
      left: -74px;
      top: 50%;
      margin-top: 0;
    }
  }

  & > .heading {
    @media (--viewport-lg) {
      max-width: 50%;
    }
  }
}

.carousel {
  clip-path: inset(-100% -100% -100% 0);
  display: flex;
  opacity: 1;
  transition: opacity var(--duration-md) ease;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  &:global(.keen-slider) {
    overflow: visible;
  }
}

.hidden {
  opacity: 0;
}

.navigation {
  display: flex;
  justify-content: flex-start;

  @media (min-width: 1400px) {
    flex-direction: column;
  }

  & > :not(:last-child) {
    margin-right: var(--size-12);

    @media (min-width: 1400px) {
      margin-bottom: var(--size-12);
      margin-right: 0;
    }
  }
}

.heading {
  padding-bottom: var(--size-24);
  color: var(--color-blue-700);
}

.buttonLayout:disabled {
  opacity: 0.4;
}
