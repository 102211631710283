.component {
  display: flex;
  align-items: center;
  grid-gap: var(--size-6);
  font-size: var(--font-size-14);
}

.category {
  font-family: var(--font-family-base);
  display: flex;

  &::after {
    content: "•";
    left: var(--size-6);
    position: relative;
    display: block;
    margin-right: var(--size-6);
  }
}
