.componentBase {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: stretch;
  overflow: hidden;
  border-radius: var(--border-radius-12);

  &::before {
    display: block;
    content: '';
    position: absolute;
    z-index: -1;
    top: 100px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-white);
  }

  & > .content {
    flex: 1 0 auto;
    order: 1;
  }

  & > .relativeToParent {
    position: static;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: flex-start;
  padding: var(--size-30) var(--size-24) var(--size-24);
  position: relative;

  @media (--viewport-md) {
    padding: var(--size-30);
  }

  & > .link {
    margin-bottom: var(--size-16);
    flex: 1 0 auto;
  }

  & > .categoryLayout {
    margin-bottom: var(--size-10);
  }

  & > .relativeToParent {
    position: static;
  }
}

.link {
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.title {
  font-size: var(--font-size-24);
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }
}

