.component {
  max-width: 100%;
  overflow: visible;
  position: relative;

  & > .navigation {
    margin-top: var(--size-12);

    @media (min-width: 1400px) {
      position: absolute;
      left: -74px;
      top: 50%;
      margin-top: 0;
    }
  }

  & > .heading {
    @media (--viewport-lg) {
      max-width: 50%;
    }
  }
}

.carousel {
  clip-path: inset(-100% -100% -100% 0);
  display: flex;
  opacity: 1;
  transition: opacity var(--duration-md) ease;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  &:global(.keen-slider) {
    overflow: visible;
  }
}

.hidden {
  opacity: 0;
}

.navigation {
  display: flex;
  justify-content: flex-start;

  @media (min-width: 1400px) {
    flex-direction: column;
  }

  & > :not(:last-child) {
    margin-right: var(--size-12);

    @media (min-width: 1400px) {
      margin-bottom: var(--size-12);
      margin-right: 0;
    }
  }
}

.heading {
  padding-bottom: var(--size-24);
}

.button:disabled {
  opacity: 0.4;
}

.componentCard {
  background-color: var(--color-blue-100);
  padding: var(--size-32) var(--size-24);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: grab;
  border-radius: var(--border-radius-20);

  &:active {
    cursor: grabbing;
  }

  & > .icon {
    width: 40%;
    margin-bottom: var(--size-12);
  }

  & > .cardTitle {
    margin: var(--size-22) 0 var(--size-16);
  }

  & > .checklist {
    width: 100%;
  }
}

.icon {
  border-radius: 50%;
  background: var(--color-blue-500);
  aspect-ratio: 1;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;

  & > .iconLayout {
    width: 50%;
  }
}

.cardTitle {
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-700);
  line-height: var(--line-height-heading);
}

.checklist {
  padding: 0;
}

.checklistItem {
  display: flex;
  align-items: center;
  gap: var(--size-12);
  font-size: var(--font-size-14);
  padding: var(--size-6);

  & > .checklistIcon {
    flex-shrink: 0;
  }
}

.checklistIcon {
  color: var(--color-green-500);
}
