.yellowLight {
  background-color: var(--color-yellow-100);
}

.yellowRegular {
  background-color: var(--color-yellow-300);
}

.orangeLight {
  background-color: var(--color-orange-100);
}

.orangeRegular {
  background-color: var(--color-orange-300);
}

.pinkLight {
  background-color: var(--color-pink-100);
}

.pinkRegular {
  background-color: var(--color-pink-300);
}

.greenLight {
  background-color: var(--color-green-100);
}

.greenRegular {
  background-color: var(--color-green-300);
}

.blueLight {
  background-color: var(--color-blue-100);
}

.blueRegular {
  background-color: var(--color-blue-300);
}
