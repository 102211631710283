.component {
  & > * {
    margin-top: var(--size-24);
  }

  & > .heading {
    margin-top: var(--size-56);
    margin-bottom: var(--size-32);
  }

  & > .subheading {
    margin-top: var(--size-40);
    margin-bottom: var(--size-24);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.componentLink {
  text-decoration: underline;
  color: var(--color-blue-700);
}

.headingSm {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-24);
  line-height: var(--line-height-heading);
}

.headingMd {
  font-family: var(--font-family-heading);
  color: var(--color-blue-700);
  font-size: var(--font-size-40);
  line-height: var(--line-height-heading);
}

.paragraph {
  font-size: var(--font-size-16);
}

.orderedList {
  list-style-type: disc;
  padding-left: var(--size-38);
  font-size: var(--font-size-18);
}

.unorderedList {
  list-style-type: decimal;
  padding-left: var(--size-38);
  font-size: var(--font-size-18);
}

.listItem {
  padding-left: 10px;

  &::marker {
    font-weight: var(--font-weight-bold);
    color: var(--color-blue-700);
  }
}

.link {
  color: var(--color-blue-500);
  font-weight: var(--font-weight-bold);
  text-decoration: none;
}
