.component {
  padding-block: var(--size-58);
  background-color: var(--color-blue-700);

  @media (--viewport-md) {
    padding-block: var(--size-103);
  }
}

.heading {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: var(--size-24);
  color: var(--color-white);

  & > :last-child {
    margin-top: var(--size-16);
  }
}

.grid {
  display: grid;
  grid-gap: var(--size-24);
  grid-template-columns: repeat(auto-fill, minmax(calc(320px - 10vw), 1fr));

  @media (--viewport-md) {
    grid-template-columns: repeat(2, minmax(560px, 1fr));
  }

  & > * {
    min-width: 0;
  }
}
