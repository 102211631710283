.component {
  padding: var(--size-8) var(--size-10);
  display: flex;
  align-items: center;
  font-size: var(--font-size-14);
  background-color: var(--color-blue-50);
  color: var(--color-blue-700);
  border-radius: var(--border-radius-4);
  line-height: 1;
}
