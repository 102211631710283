.page {
  & > .contentSection {
    margin-top: var(--size-40);
    margin-bottom: var(--size-120);
  }
}

.componentContent {
  & > .image,
  & > .commissiemodel {
    margin-top: var(--size-56);
    margin-bottom: var(--size-56);
  }
}

.center {
  & > * {
    margin: auto;
  }
}
