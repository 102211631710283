.page {
  background-color: var(--color-green-300);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-120) var(--size-18);

  & > * {
    width: 100%;
  }
}

.layout {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & > .text {
    @media (--viewport-md) {
      width: 50%;
    }
  }

  & > .illustration {
    @media (--viewport-md) {
      width: 40%;
    }
  }
}

.text {
  color: var(--color-blue-700);

  & > :not(:last-child) {
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-bottom: var(--size-40);
    }
  }

  & > .link {
    margin-top: var(--size-24);

    @media (--viewport-md) {
      margin-top: var(--size-48);
    }
  }
}

.iconNotFound {
  & > * {
    height: 100%;
  }
}

.componentLink {
  display: flex;
  align-items: center;
  color: var(--color-blue-500);
  text-decoration: none;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-18);

  & > .iconForward {
    margin-right: var(--size-18);
    color: var(--color-white);
  }
}
