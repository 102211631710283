.cardCarousel {
  overflow: hidden;
}

.page {
  & > * {
    margin-bottom: var(--size-134);
  }

  & > .hero {
    margin-bottom: 0;
  }
}

.commissieModel {
  overflow: hidden;
}
