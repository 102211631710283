.page {
  padding-top: var(--size-56);
  padding-bottom: var(--size-120);

  & > .header {
    margin: var(--size-56) 0;
  }
}

.header {
  color: var(--color-blue-700);
}

.componentContent {
  & > .image {
    margin-top: var(--size-56);
    margin-bottom: var(--size-56);
  }
}

.center {
  & > * {
    margin: auto;
  }
}
