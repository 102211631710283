.component {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: var(--size-24);
  grid-row-gap: var(--size-30);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
