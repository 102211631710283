.component {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 75px;
    background-color: var(--color-background);
    z-index: -1;

    @media (--viewport-md) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -75%;
    height: 250vw;
    width: 250vw;
    z-index: -1;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;

    @media (--viewport-md) {
      display: none;
    }
  }
}

.colorGreen {
  @media (--viewport-md) {
    background-color: var(--color-green-100);
  }

  &::after {
    background-color: var(--color-green-100);
  }
}

.colorBlue {
  @media (--viewport-md) {
    background-color: var(--color-blue-100);
  }

  &::after {
    background-color: var(--color-blue-100);
  }
}

.colorOrange {
  @media (--viewport-md) {
    background-color: var(--color-orange-100);
  }

  &::after {
    background-color: var(--color-orange-100);
  }
}

.layout {
  & > .layoutInner {
    min-height: 465px;
  }
}

.layoutInner {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  color: var(--color-blue-700);
  padding-top: var(--size-24);

  @media (--viewport-md) {
    grid-template-columns: 3fr 2fr;
  }

  & > .image {
    align-self: center;
    justify-self: center;
  }
}
