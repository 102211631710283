.componentBase {
  font-family: var(--font-family-heading);
  line-height: var(--line-height-heading);
}

.subtitle {
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-light);
}

.componentMd {
  & > .subtitle {
    margin-top: var(--size-6);
  }
}

.componentLg {
  & > .subtitle {
    margin-top: var(--size-18);
  }
}

.componentXl {
  & > .subtitle {
    margin-top: var(--size-12);

    @media (--viewport-md) {
      margin-top: var(--size-24);
    }
  }
}

.componentHeaderTitleMd {
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-super);

  @media (--viewport-md) {
    font-size: var(--font-size-52);
  }
}

.componentHeaderTitleLg {
  font-size: var(--font-size-42);
  font-weight: var(--font-weight-super);

  @media (--viewport-md) {
    font-size: var(--font-size-72);
  }
}

.componentHeaderTitleXl {
  font-size: var(--font-size-48);
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-super);

  @media (--viewport-md) {
    font-size: var(--font-size-82);
  }
}

.componentHeaderSubtitleMd {
  font-size: var(--font-size-24);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }
}

.componentHeaderSubtitleLg {
  font-size: var(--font-size-32);
}

.componentHeaderSubtitleXl {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-32);
}
