.component {
  position: relative;
  z-index: 0;
  color: var(--color-blue-700);
  display: flex;
  flex-direction: column-reverse;
  padding-top: var(--size-32);

  @media (--viewport-md) {
    padding-top: var(--size-120);
    padding-bottom: var(--size-120);
  }

  & > .image {
    max-width: 350px;
    margin: 0 auto;

    @media (--viewport-lg) {
      position: absolute;
      z-index: -1;
      right: calc(50vw - 500px);
      top: 50%;
    }
  }
}

.componentContent {
  padding-top: var(--size-58);
  padding-bottom: var(--size-58);

  @media (--viewport-md) {
    padding-top: var(--size-96);
    padding-bottom: var(--size-96);
  }

  & > .contentHeadingLayout {
    @media (--viewport-lg) {
      max-width: 475px;
    }
  }
}

.title {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-32);
  line-height: var(--line-height-heading);

  @media (--viewport-md) {
    font-size: var(--font-size-52);
  }
}

.contentHeadingLayout {
  & > :not(:first-child) {
    margin-top: var(--size-18);
  }
}

.image {
  display: flex;
  justify-content: center;
  border-radius: 50%;

  @media (--viewport-lg) {
    transform: translateY(-50%);
  }
}
