.component {
  background-color: var(--color-blue-100);
  padding: var(--size-42) 5vw;
  position: relative;

  @media (--viewport-lg) {
    padding: var(--size-103) calc(2.5vw + var(--size-24));
  }

  & > .inner {
    max-width: var(--size-container-max-width-xl);
    margin: 0 auto;
  }
}

.inner {
  display: flex;
  flex-direction: column;

  @media (--viewport-lg) {
    flex-direction: row;
    align-items: center;
  }

  & > .info {
    margin-right: var(--size-56);
  }

  & > .benefitsList {
    margin: var(--size-24) 0;

    @media (--viewport-lg) {
      margin: 0;
    }
  }

  & > .actions {
    margin-top: var(--size-24);
  }
}

.actions {
  display: flex;
  flex-direction: column;

  @media (--viewport-lg) {
    display: none;
  }

  & > .link {
    margin-top: var(--size-12);

    &:first-child {
      margin-top: 0;
    }
  }
}

.info {
  color: var(--color-blue-700);

  & > .subtitle {
    margin-top: var(--size-6);
  }

  & > .infoActions {
    @media (--viewport-lg) {
      margin-top: var(--size-56);
    }
  }
}

.infoActions {
  display: none;

  @media (--viewport-lg) {
    display: flex;
    align-items: center;
  }

  & > .link {
    margin-left: var(--size-24);

    &:first-child {
      margin-left: 0;
    }
  }
}

.link {
  display: flex;
}

.componentList {
  list-style: none;
  padding: 0;

  @media (--viewport-lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--size-12);
    row-gap: var(--size-24);
  }

  & > .listItem {
    margin-top: var(--size-18);

    @media (--viewport-lg) {
      margin-top: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}

.componentListItem {
  display: flex;
  align-items: center;

  & > .icon {
    flex-shrink: 0;
  }

  & > .text {
    margin-left: var(--size-6);
  }
}

.icon {
  color: var(--color-green-500);
}

.text {
  color: var(--color-blue-700);
  font-size: var(--font-size-18);
  line-height: var(--line-height-benefits);
}
