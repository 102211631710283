.page {
  padding-top: var(--size-56);
  padding-bottom: var(--size-120);

  & > .header {
    margin: var(--size-56) 0;
  }
}

.header {
  color: var(--color-blue-700);
}
