.component_root {
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
}

.siteHeader {
  --size-container-siteheader: 1500px;

  padding: var(--size-12) 5vw 0;

  @media (--viewport-md) {
    padding: var(--size-12) calc(2.5vw + var(--size-24)) 0;
  }

  & > * {
    max-width: var(--size-container-siteheader);
    margin: 0 auto;
  }
}

.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--size-header-height-sm) !important;
  padding-inline: var(--size-32);
  color: var(--color-white);
  background-color: var(--color-blue-700);
  border-radius: var(--border-radius-90);

  @media (--viewport-md) {
    height: var(--size-header-height-md) !important;
  }

  & > .logo {
    max-width: 70%;

    @media (--viewport-md) {
      max-width: 80%;
    }
  }
}

.logo {
  color: var(--color-white);
  line-height: 1;
}

.componentMenuLarge {
  font-size: var(--font-size-14);
  align-items: center;
  display: none;

  @media (--viewport-lg) {
    display: flex;
  }
}

.componentMenuMobile {
  display: flex;

  @media (--viewport-lg) {
    display: none;
  }
}

.componentLanguageSwitchLarge {
  border-left: 1px solid var(--color-blue-300);
  padding-left: var(--size-30);

  & > .language {
    min-height: 40px;
  }
}

.language {
  display: flex;
  align-items: center;
}

.componentDesktopNavList {
  display: flex;
  list-style: none;
  padding-right: var(--size-30);

  & > :not(:last-child) {
    margin-right: var(--size-30);
  }
}

.componentDesktopNavItem,
.componentLanguageSwitchLarge {
  display: flex;
  align-items: center;
  position: relative;

  & > .hoverMenu {
    width: 210px;
    position: absolute;
    top: var(--size-24);
    display: none;
  }

  &:hover {
    & > .hoverMenu {
      display: block;
    }
  }
}

.navLink {
  color: var(--color-white);
  font-size: var(--font-size-14);
  text-decoration: none;
  display: flex;
  align-items: center;
  line-height: var(--line-height-navigation);

  & > .navLinkIcon {
    margin-right: var(--size-8);
  }
}

.desktopNavItemSeparator {
  border-left: 1px solid #4040bd;
  padding-left: var(--size-30);
}

.navLinkIcon {
  display: flex;
  align-items: center;
}

.desktopNavItemSubitemLink {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  color: var(--color-white);
  transition: transform var(--duration-sm) ease;
  transform: translateX(calc(var(--size-24) * -1));
  text-decoration: none;

  & > :first-child {
    margin-right: var(--size-6);
  }

  &:hover {
    transform: translateX(0);

    & > .hoverIcon {
      transform: translateX(0);
    }
  }
}

.hoverIcon {
  display: flex;
  align-items: center;
  transform: translateX(calc(var(--size-24) * -1));
  transition: transform var(--duration-sm) ease;
}

.hoverMenu {
  padding: var(--size-30) var(--size-18) var(--size-18);
  list-style: none;
  border-radius: 0 0 var(--border-radius-12) var(--border-radius-12);

  &:not(.hoverMenuMobile) {
    background-color: var(--color-blue-700);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-12);
  }
}

.hoverMenuMobile {
  font-size: var(--font-size-22);
}

.componentLanguageSwitchMobile {
  position: relative;

  & > .languageSwitchMobilePanel {
    position: absolute;
    right: calc(-2.5vw - var(--size-24));
    bottom: 0;
    left: calc(-2.5vw - var(--size-24));
  }
}

.languageSwitchMobilePanel {
  display: none;
  align-items: center;
  flex-direction: column;
  padding-top: var(--size-30);
  padding-bottom: var(--size-96);
  color: var(--color-black);
  background-color: var(--color-white);

  &.isOpen {
    display: flex;
  }
}

._rootdrawerInner {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  background-color: var(--color-blue-700);

  @media (--viewport-md) {
    max-width: 500px;
  }
}

.componentMobileNavList {
  padding: 0;
  list-style: none;
  font-weight: 800;
  color: var(--color-white);
  font-size: var(--font-size-22);
  position: relative;

  & > :not(:last-child) {
    margin-bottom: var(--size-54);
  }

  & > .relativeToParent {
    position: static;
  }
}

.componentMobileNavListItem {
  position: relative;

  & > .drawerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.mobileNavItem {
  color: var(--color-white);
  text-decoration: none;
  font-size: var(--font-size-22);
  font-weight: 800;
}

.componentCloseButton {
  transition: transform var(--duration-sm);

  &:hover {
    transform: rotate(90deg);
  }
}

.drawerLayout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 100vh !important;

  & > * {
    width: 100%;
  }

  & > .drawerFooter {
    flex-grow: 1;
  }
}

.drawerHeaderLayout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--size-header-height-sm) !important;

  @media (--viewport--md) {
    height: var(--size-header-height-md) !important;
  }

  & > .logo {
    max-width: 70%;
  }
}

.drawerFooter {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & > .languageSwitchMobile {
    width: 100%;
    height: 100%;
  }
}

.drawerContent {
  position: relative;
  padding-top: var(--size-30);
  padding-bottom: var(--size-54);
}

.componentMobileNavListOverlay {
  background: var(--color-blue-700);
  width: 100vw !important;
  height: 100vh !important;

  @media (--viewport-md) {
    max-width: 500px !important;
  }

  & > :first-child {
    margin-top: var(--size-30);
    margin-bottom: var(--size-30);
  }
}

.componentMobileSubmenuList {
  list-style: none;
  padding-left: 0;

  & > :not(:last-child) {
    margin-bottom: var(--size-40);
  }
}

.componentMobileSubmenuListItem {
  color: var(--color-white);
}

.mobileSubmenuListItemLink {
  color: var(--color-white);
  text-decoration: none;
}

.backButton {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-base-400);
}

.dropdownButton {
  font-size: var(--font-size-14);
}
