:export {
  textPadding: 24px;
  halfTextPadding: 12px;
}

.component {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-gray-100);

  & > * {
    width: 100%;
  }
}

.title {
  display: flex;
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-heading);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-align: left;
  padding: var(--size-24);
  font-size: var(--font-size-18);
  font-family: var(--font-family-base);

  & > .toggle {
    margin-left: var(--size-12);
    flex-shrink: 0;
    height: fit-content;
  }
}

.toggle {
  display: flex;
  align-items: flex-start;
  color: var(--color-blue-500);
  transition: transform var(--duration-sm) var(--ease-in-out);

  &.isOpen {
    transform: rotate(180deg);
  }
}

.content {
  overflow: hidden;
  text-align: left;
  transition: padding var(--duration-xs);
  padding: 0 var(--size-24);

  &:not(.isOpen) {
    padding-bottom: 0;
  }
}

.text {
  & > :not(:first-child) {
    margin-top: var(--size-24);
  }
}
