.componentBase {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--font-size-16);
  color: inherit;

  & > .icon {
    margin-right: var(--size-12);
    width: var(--size-24);
    height: var(--size-24);
  }
}

.componentWithIcon {
  white-space: nowrap;

  & > .icon {
    width: var(--size-24);
  }
}

.icon {
  display: flex;
  align-items: center;
}

.componentButtonBase {
  padding: var(--size-12) var(--size-24) var(--size-12) var(--size-12);
  line-height: var(--line-height-button);
  color: var(--color-white);
  background-color: var(--color-blue-500);
  white-space: nowrap;
  border-radius: var(--border-radius-4);
}

.componentIcon {
  padding: var(--size-6);

  & > .icon {
    margin-right: 0;
  }
}

.componentButtonNoLabel {
  padding: var(--size-12);

  & > .icon {
    margin-right: 0;
  }
}
