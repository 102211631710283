.component {
  font-family: var(--font-family-base);
  font-size: var(--font-size-24);
  color: var(--color-blue-700);
  padding-top: var(--size-40);
  padding-bottom: var(--size-40);

  @media (--viewport-md) {
    font-size: var(--font-size-28);
  }
}
