:export {
  spacing: 30px;
  headingTop: 54px;
  slideHeight: 550px;
}

.component {
  position: relative;

  & > .heading {
    top: var(--size-54);
  }
}

.heading {
  position: sticky;
  text-align: center;
  color: var(--color-blue-700);
}

.componentSlide {
  contain: layout;

  & > * {
    height: 550px;
    overflow: hidden;
    max-height: calc(100vh - var(--size-54) - 2 * var(--size-30));
  }
}

.slide {
  position: sticky;
}

.componentContent {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: var(--size-30);
  border-radius: var(--border-radius-18);
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'text'
    'image';

  & > .text {
    grid-area: text;
    z-index: 1;
  }

  & > .image {
    grid-area: image;
    z-index: 0;
  }

  @media (--viewport-md) {
    padding: var(--size-74);
  }

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'text image';
  }
}

.ondernemer {
  color: var(--color-blue-700);
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-18);
}

.text {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  grid-gap: var(--size-18);
  color: var(--color-blue-700);
}

.image {
  position: relative;

  & > * {
    position: absolute;
    right: -15vw;
    bottom: -15vw;
    width: 350px;
    max-width: 70%;

    @media (--viewport-md) {
      bottom: -5vw;
      right: -10vw;
      width: 400px;
    }

    @media (--viewport-lg) {
      top: -50%;
      right: -40%;
      bottom: unset;
      width: 500px;
      max-width: 100%;
    }
  }
}

.yellowLight {
  background-color: var(--color-yellow-100);
}

.yellowRegular {
  background-color: var(--color-yellow-300);
}

.orangeLight {
  background-color: var(--color-orange-100);
}

.orangeRegular {
  background-color: var(--color-orange-300);
}

.pinkLight {
  background-color: var(--color-pink-100);
}

.pinkRegular {
  background-color: var(--color-pink-300);
}

.greenLight {
  background-color: var(--color-green-100);
}

.greenRegular {
  background-color: var(--color-green-300);
}

.blueLight {
  background-color: var(--color-blue-100);
}

.blueRegular {
  background-color: var(--color-blue-300);
}

.roundedImage {
  border-radius: 50%;
}
