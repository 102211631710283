.page {
  position: relative;
  display: flex;
  flex-direction: column;

  & > .backToTop {
    position: sticky;
    align-self: flex-end;
    bottom: var(--size-40);
    right: var(--size-40);
    height: 50px;
    width: 50px;
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      bottom: var(--size-80);
      right: var(--size-80);
    }
  }
}

.contentSection {
  padding-top: var(--size-80);
  padding-bottom: var(--size-80);
}

.pageFaqContainer {
  & > .category {
    margin-bottom: var(--size-80);
  }
}
