.componentBase {
  display: flex;
  justify-content: center;
  padding: 0 5vw;

  @media (--viewport-md) {
    padding: 0 calc(2.5vw + var(--size-24));
  }

  & > .layout {
    width: 100%;
  }
}

.componentSm {
  & > .layout {
    max-width: var(--size-container-max-width-sm);
  }
}

.componentMd {
  & > .layout {
    max-width: var(--size-container-max-width-md);
  }
}

.componentLg {
  & > .layout {
    max-width: var(--size-container-max-width-lg);
  }
}
