.component {
  overflow: hidden;

  & > .componentReadMore {
    margin-top: var(--size-42);
  }

  & > .header {
    margin-bottom: var(--size-32);
  }

  & > .disclaimer {
    margin-top: var(--size-32);
    width: 100%;
  }
}

.disclaimer {
  display: block;
  text-align: center;
}

.header {
  text-align: center;
  color: var(--color-blue-700);
}

.componentCardGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "platinum gold silver bronze";
  gap: var(--size-22);

  @media (--viewport-md) {
    grid-template-columns: repeat(4, 1fr);
  }

  & > .cardPlatinum {
    grid-area: platinum;
    margin-bottom: var(--size-96);
  }

  & > .cardGold {
    grid-area: gold;

    @media (--viewport-md) {
      margin-top: var(--size-32);
      margin-bottom: var(--size-64);
    }
  }

  & > .cardSilver {
    grid-area: silver;

    @media (--viewport-md) {
      margin-top: var(--size-64);
      margin-bottom: var(--size-32);
    }
  }

  & > .cardBronze {
    grid-area: bronze;

    @media (--viewport-md) {
      margin-top: var(--size-96);
    }
  }
}

.layout {
  &.indeterminate {
    visibility: hidden;
  }
}

.componentCardBase {
  background-color: var(--color-blue-100);
  padding: var(--size-27);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius-20);

  & > .icon {
    width: 50%;
    margin-bottom: var(--size-12);
  }

  & > .cardTitle {
    margin: var(--size-22) 0 var(--size-16);
  }

  & > .checklistLayout {
    width: 100%;
  }
}

.icon {
  border-radius: 50%;
  background: var(--color-blue-500);
  aspect-ratio: 1;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;

  & > .iconLayout {
    width: 50%;
  }
}

.cardTitle {
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-700);
  line-height: var(--line-height-heading);
}

.componentChecklist {
  padding: 0;
}

.checklistItem {
  display: flex;
  align-items: center;
  gap: var(--size-8);
  font-size: var(--font-size-14);
  padding: var(--size-6);

  & > .checklistIconLayout {
    flex-shrink: 0;
  }
}

.checklistIconLayout {
  color: var(--color-green-500);
}

.componentReadMore {
  display: flex;
  justify-content: center;
}
